<template>
  <EntityList
    ref="videoList"
    :config="config"
    :columns="columns"
    :filter-vals.sync="filterVals"
    :filters="filters"
    :extra-validation="extraValidation"
    :custom-query="{ congress: congressID }"
    :formatted-columns="formattedColumns"
    authority="Congress_Management_Video"
    :form-fields="formFields"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import moment from 'moment'
import storeModule from './videoStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      visibilityOptions: [
        { id: 1, title: 'Public' },
        { id: 2, title: 'Unlisted' },
        { id: 3, title: 'Hidden' },
      ],
      roomOptions: [],
      workingGroupOptions: [],
      speakerOptions: [],
    }
  },
  computed: {
    congressID() {
      return this.$route.params.id
    },
    congress() {
      return this.$store.getters['app-common/selectedEntity']
    },
    congressStart() {
      return moment(this.congress.startDate).format('YYYY-MM-DD')
    },
    congressEnd() {
      return moment(this.congress.endDate).format('YYYY-MM-DD')
    },

    config() {
      return {
        store: storeModule,
        module: 'videos',
        endpoint: 'videos',
        route: 'videos',
        title: {
          single: this.$t('Video Add'),
          plural: this.$t('Videos'),
        },
      }
    },

    columns() {
      return [
        { key: 'ordering', label: this.$t('ordering'), sortable: true },
        { key: 'title', label: this.$t('Title'), sortable: true },
        { key: 'vimeoId', label: this.$t('Vimeo ID'), sortable: false },
        { key: 'speaker.title', label: this.$t('Speaker'), sortable: false },
        { key: 'room.title', label: this.$t('Room'), sortable: false },
        // { key: 'tags', label: this.$t('Tags'), sortable: false },
        { key: 'startDate', label: this.$t('Date'), sortable: true },
        { key: 'unlisted', label: this.$t('Unlisted'), sortable: false },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [
        // { key: 'isPrivate', type: 'boolean' },
        { key: 'unlisted', type: 'boolean' },
        { key: 'startDate', type: 'date' },
      ]
    },
    filters() {
      return [
        {
          name: 'room',
          label: this.$t('Room'),
          options: this.roomOptions,
        },
        {
          name: 'workinggroup',
          label: this.$t('Working Group'),
          options: this.workingGroupOptions,
        },
        {
          name: 'speaker',
          label: this.$t('Speaker'),
          options: this.speakerOptions,
        },
        {
          name: 'datefrom',
          type: 'date',
          label: this.$i18n.t('Date From'),
          maxDateFieldIdx: '4',
          minDate: this.congressStart,
          maxDate: this.congressEnd,
        },
        {
          name: 'dateto',
          type: 'date',
          label: this.$i18n.t('Date To'),
          minDateFieldIdx: '3',
          minDate: this.congressStart,
          maxDate: this.congressEnd,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          maxDateFieldIdx: '6',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          minDateFieldIdx: '5',
          maxDate: new Date(),
        },
      ]
    },
    formFields() {
      return [
        {
          id: 'congress',
          type: 'hidden',
          initVal: { id: this.congressID },
          hidden: true,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('Title'),
          required: true,
        },
        {
          id: 'workingGroup',
          object: true,
          type: 'select',
          label: this.$t('Working Group'),
          options: this.workingGroupOptions,
          colSize: 6,
        },
        {
          id: 'speaker',
          object: true,
          type: 'select',
          label: this.$t('Speaker'),
          options: this.speakerOptions,
          colSize: 6,
        },
        {
          id: 'room',
          object: true,
          type: 'select',
          label: this.$t('Room'),
          options: this.roomOptions,
          colSize: 6,
        },
        {
          id: 'startDate',
          type: 'date',
          label: this.$t('Date'),
          colSize: 6,
          minDate: this.congressStart,
          maxDate: this.congressEnd,
        },
        {
          id: 'ordering',
          type: 'text',
          label: this.$t('ordering'),
          required: true,
          rules: 'integer',
          colSize: 3,
          initVal: 1,
        },
        {
          id: 'visibility',
          type: 'select',
          label: this.$t('Visibility'),
          options: this.visibilityOptions,
          required: true,
          initVal: 2,
          colSize: 9,
        },
        {
          id: 'video',
          type: 'video',
          label: this.$t('Video'),
          callback: this.refetchData,
        },
        // {
        //   id: 'tags',
        //   type: 'text',
        //   label: this.$t('Tags'),
        //   required: false,
        // },
        {
          id: 'description',
          type: 'quill',
          label: this.$t('Description'),
          required: false,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCongressRooms', this.congressID).then(response => {
      this.roomOptions = response.data
    })
    this.$store.dispatch('app-common/fetchWorkingGroups').then(response => {
      this.workingGroupOptions = response.data
    })
    this.$store.dispatch('app-common/fetchParticipants').then(response => {
      this.speakerOptions = response.data
    })
    if (!this.congress.id) {
      this.$store.dispatch('app-common/fetchCongress', this.congressID).then(response => {
        this.$store.commit('app-common/selectedEntity', response.data)
      })
    }
  },
  methods: {
    extraValidation(entity) {
      if (!entity.vimeoId) {
        return this.$t('Please wait until video upload is completed!')
      }
      return ''
    },
    refetchData() {
      this.$refs.videoList.handleEntitySave()
    },
  },
}
</script>
